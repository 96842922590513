//Notifications
.dropdown {
    display:inline-block;
    padding:20px 120px 0px 0px;
    float:right;
    z-index: 10;
  }
.glyphicon-bell {
    font-size:1.4rem;
    color:#eee;
  }

.menu {
  right: -400px;
  height: 100%;
  position: fixed;
  width: 400px;
  z-index: 11;
  background-color: $primary-color;
  transition: .2s
}

.menu ul {
  border-top: 1px solid #636366;
  list-style: none;
  margin: 0;
  padding: 0;
}

.menu li {
  line-height: 20px;
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  font-size:11px;
  padding-left: 20px;
  padding-top: 8px;
  padding-bottom:9px;
  padding-right: 15px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.07);
  float: left;
  width: 100%;
  color:#fff;
   &:hover {
   	background-color: $darker-primary-color;
   }
}

.menu a li p {
  margin-top:5px;
}

.bar {
  right: 0px;
}

.notifications-header p {
    font-size: 20px;
    font-family: 'Roboto', sans-serif;
    font-weight: 300;
    color: #fff;
    text-align: center;
    padding-top: 0.5em;
}

.notification-profile-image {
    border-radius: 25px;
    height:45px; 
    width:45px;
    margin-right:15px;
    float: left;
}