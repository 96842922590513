$primary-color: #424242;
$darker-primary-color: #3A3A3A;
$secondary-color: #008ae6;
$link-color:#337ab7;
$top-bar-color:#2196F3;
a{
    color: $link-color;
    text-decoration: none;
}
p{
    margin: 0 0 10px;
    line-height: 1.4285em;
    &:last-child{
    margin-bottom: 10px;
    }
}
h1
{
	font-size:28px;
    font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
    min-height: 0rem;
    font-weight: 200;
}
h3{
    &:first-child{
    margin-top: 20px;
    margin-bottom: 10px;
    }
}
h4{
    margin: 0;
    font-weight: normal;
}

body {
  background-color: #edf0fd;

}
.smalltext
{
    font-size: 10px;
}

.grey
{
    border-color: grey;
}

.movedown
{
    margin-top: 20px;
}

.topbar
{
     background-color: #313d4e;
     width: 100%;
     height: 40px;

}
.form-control[disabled] {
  background-color: #424242;
}

.navbar-toggle {
	    display: block;
}
#page-content-wrapper {
    width: 100%;
    position: absolute;
    padding: 15px 15px 15px 270px;
  @media(min-width:768px)
	{
        padding: 0px 0px 0px 0px;
	position: relative;
	}
	@media(min-width:992px) {
	padding:0px 0px 0px 270px;
	position:relative;
	}
    @media(max-width:768px)
    {
       padding: 0px 0px 0px 0px;
       position: relative;
    }
}

.boxspace
{
	margin-right:10px;
	padding:10px;
}

.taskcase
{
    padding: 5px 5px 15px 20px;
    background-color: #cce5ff;

	hr
	{
		margin-top: 10px;
		margin-bottom: 10px;
	}
}


.btn-primary
{
	background-color: $primary-color; //#D18551 Ornage
	border-color: $primary-color; // #D18551 Ornage
}

.dropzone
{
	    border: 2px dotted $secondary-color;
}

.ui.selection.dropdown .menu>.item {
    font-size: 15px;
}

.ui.selection.dropdown
{
    margin-left: 0;
}

.form-inline .form-control {
    @media (min-width: 768px)
    {
        width:100%;
    }
    margin-bottom: 15px;
}
.removeleft
{
    padding-left: 0;
}

.removeright
{
    padding-right: 0;
}
.panel-header
{
    color:#fff !important;
    background-color:#3A485C !important;
}

.panel-heading
{
    padding: 1px 1px 1px 15px;
    p
    {
      margin-top:8px;
      font-size:16px;
    }
}

.navmenu-fixed-left, .navbar-offcanvas.navmenu-fixed-left
{
  border-width: 0 0px 0 0;
}

.navbar-default
{
  background-color: $top-bar-color;
  border:none;
  border-radius:0px;
  height:58px;
}

#permissions-table th:first-child {
  width: 200px;
}
