.navmenu
{
    background-color: $primary-color;
    top: 0;
    width: 270px;
    margin: 0;
    padding: 0;
    list-style: none;
}
.sidebarheader
{
    background-color: $secondary-color;
    padding-top: 10px;
    padding-bottom: 3px;
    padding-left: 10px;
    text-transform: uppercase;
    color: #fff;
    margin-top: 15px;
}

.list-group-item 
{

    background-color: $primary-color;
    border: none;

    &:first-child
    {
		    border-top-right-radius: 0px;
    		border-top-left-radius: 0px;
    }
    &:last-child
    {
    	    border-bottom-right-radius: 0px;
    		border-bottom-left-radius: 0px;
    }
}

.siderbar-top{
  border-bottom: 2px solid hsla(0,0%,100%,.07);
}

.sidebar-arrow {
  float: right;
  transition: 300ms linear all;
}

.arrow-up {
  transform: rotate(0deg);
  transition: transform 0.2s linear;
}

.arrow-down{
  transform: rotate(180deg);
  transition: transform 0.2s linear;
}

.childlist{
    background-color: $primary-color;
    padding-left:50px;
}

a.list-group-item
{
    color: #fff;
	font-size:13px;
    font-family: 'Roboto', sans-serif;
    font-weight: 300;

  &:focus{
    background-color: $darker-primary-color;
    color:#fff;
  }
  &:hover {
    background-color: $darker-primary-color;
    color:#fff;
  }
}

.sidebar-icon {
  padding-right: 40px;
  font-size: 15px;
}

.panel
{
    border-radius: 0px;
    border:0;
    box-shadow: 0 0px 0px rgba(0, 0, 0, 0.05);
}

.shadow
{
    -webkit-box-shadow: 1px 1px 10px 0px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 1px 1px 10px 0px rgba(0, 0, 0, 0.3);
    box-shadow: 1px 1px 10px 0px rgba(0, 0, 0, 0.3);
}

.logospace
{
   font-size: 18px;
   padding-left: 80px;
   @media(max-width:768px)
   {
   	 	padding-left: 15px;
        font-size: 18px;
   }

}

.activity-feed {
  padding: 15px;
    .feed-item {
      position: relative;
      padding-bottom: 20px;
      padding-left: 30px;
      border-left: 2px solid #e4e8eb;
    }
    .feed-item:last-child {
        border-color: transparent;
    }
    .feed-item:after {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: -6px;
      width: 10px;
      height: 10px;
      border-radius: 6px;
      background: #fff;
      border: 1px solid #f37167;
    }
    .feed-item .activity-date {
      position: relative;
      top: -5px;
      color: #6E757D;
      text-transform: uppercase;
      font-size: 13px;
    }
    .feed-item .activity-text {
      position: relative;
      top: -3px;
    }

}

/*===== custom css ========*/
/*===== custom css ========*/

.big-menu .menu-txt-toggle {
  left: 280px;
  -webkit-transition: left 0.25s;
  transition: left 0.25s;
}

.menu-txt-toggle {
  display: none;
  float: none;
  left: 25px;
  position: absolute;
  z-index: 999999;
  background: #424242;
  -webkit-transition: left 0.25s;
  transition: left 0.25s;
}

.myNavmenu-icons .menu-txt-toggle {
  left: 75px;
}

.menu-txt-toggle .icon-bar {
  background: #fff;
}

.myNavmenu-icons {
  .navmenu {
    width: 60px;
  }
  .siderbar-top img {
    width: 100%;
  }
  nav#myNavmenu {
    #menu-txt {
      display: none;
    }
    .list-group.panel a {
      .sidebar-arrow {
        display: none;
      }
      .sidebar-icon, .sidebar-arrow {
        font-size: 1.4em;
        vertical-align: middle;
        padding: 0;
        line-height: initial;
      }
    }
    .childlist {
      padding-left: 20px;
      background: #222;
    }
  }
}

.navbar-default .navbar-toggle {
  z-index: 10;
  right: 0;
  position: absolute;
}

#myNavmenu p.list-group-item.siderbar-top {
  text-align: center;
}

#page-content-wrapper {
  padding: 20px 20px 20px 20px;
  position: relative;
}

@media (min-width: 768px) {
  .big-menu #page-content-wrapper {
    padding-left: 270px;
    padding-left: 270px;
  }
  .myNavmenu-icons.big-menu #page-content-wrapper {
    padding-left: 60px;
  }
}

@media (min-width: 992px) {
  .menu-txt-toggle {
    display: block;
    left: 280px;
  }
  .myNavmenu-icons .navmenu {
    /*width: 125px;*/
  }
  .navmenu {
    width: 270px;
    transition: width 0.25s;
  }
  #myNavmenu p.list-group-item.siderbar-top {
    text-align: left;
  }
  #page-content-wrapper {
    padding: 20px 20px 20px 270px;
    position: relative;
  }
  .myNavmenu-icons #page-content-wrapper {
    padding-left: 60px;
  }
  .navbar-default .navbar-toggle {
    display: none;
  }
}